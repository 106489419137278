import * as React from "react";

import "./tooling.css";

const Tooling = ({ name, image, children }) => {
    function onMouseEnterToolingKasten(name) {
        window._paq.push([`trackEvent`, 'UI', 'view', 'tooling', name]); // Matomo event tracking
    }

  return (
    <div className="col3 toolings flex" onMouseEnter={() => onMouseEnterToolingKasten(name)} role='tooltip'>
      <div className="grau-bg tooling">
        <h3 className="titel_tool">{name}</h3>

        <div className="img-parent">
          <img src={image} alt="content"></img>
        </div>

        <div className={"buttonparent"}>
          <button className={"service-hover"}>
            <span>i</span>
          </button>
        </div>
      </div>

      <div className="tooling-card-hidden">
        <h3 >{name}</h3>
        <p>
          {children}
        </p>
      </div>
    </div>
  );
};
export default Tooling;
