import * as React from "react"
import {Helmet} from "react-helmet";
import {useBreakpoint } from "gatsby-plugin-breakpoints";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout";
import Abschnitt from "../components/abschnitt";
import Insights from "../components/insights/insights";
import Header from "../components/header/header";
import Product from "../components/teaser/teaser";
import { Service, Services } from "../components/service";
import { tooling as Tooling, Toolings } from "../components/tooling";
import { identity as Indentity, clients as Clients, intro as Intro } from "../components/identity";
import Trenner from "../components/trenner";
import FlyingContactClick from "../components/contactFlyingButton_click.js";
import SEO from './../components/generics/seo';

import "../components/contactFlyingButton.css";
import "../styles/global.css"

/* --- IMAGES --- */
/*Service*/
import { Beratung, Interaction, Konzept, PraesentationWebp, softwareentwicklung, uiux } from './../images/Service';

/*Tooling*/
import { multiuser, spatial, crossplattform, iot, machinelearning } from './../images/Tooling';
import Slider from "react-slick";
import {graphql} from "gatsby";
/* --- END IMAGES --- */

const helm = {
    title: 'DENKUNDMACH - Multimedia Designagentur',
    url: 'https://denkundmach.de',
    description: '',
}

const IndexPage = ({data, location}) => {
  const breakpoints = useBreakpoint();

  // i18n locale {/ = de} | {/en/ = en}
    const {t} = useTranslation();
    const {language} = useI18next();
    helm.language = language;
    helm.title = t('homepage title');

    const sliderSettings2 = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    const sliderSettingsMobile = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

  return (
      <>
          <SEO
              lang={language}
              title={t('homepage title')}
              canonical={helm.url}
              description={helm.description}
              productLDJSON={true} />

          <Layout>
              <Header/>
              {breakpoints.md ? <></> :  <Trenner verysmall={true}/> }

              {!breakpoints.sm ? <></> : <Intro/> }
              <Abschnitt>
                  {!breakpoints.sm ? <Intro/> : <></> }
                  <Indentity>
                      {/* Translation inline!*/}
                      {breakpoints.md ?
                          <p className={'big-text'} dangerouslySetInnerHTML={{__html: t('homepage haupttext')}}/>
                          :
                          <p className={'big-text'} dangerouslySetInnerHTML={{__html: t('homepage haupttext mobile')}}/>
                      }
                  </Indentity>

                  <Clients/>
              </Abschnitt>

              <Trenner id="SHOWCASE" small={true}/>

              <Abschnitt line={true} headline={"Showcases"}>
                  <p>{t('homepage showcase teaser')}</p>
                  <Trenner verysmall={true}/>
                  <Product sid={"BlickPunkt-Moritzburg"} />
                  <Product sid={"XR-Mobility"} />
                  <Product sid={"augmented-livingspaces"} />
{/*
                  <div style={{display: 'none'}}>
                      <Slider {...sliderSettings2}>
                          <Product sid={"augmented-livingspaces"} />
                          <Product sid={"augmented-mobility"} />

                          <Product sid={"augmented-livingspaces"} />
                          <Product sid={"augmented-livingspaces"} />
                          <Product sid={"augmented-mobility"} />

                          <Product sid={"augmented-livingspaces"} />
                          <Product sid={"augmented-livingspaces"} />
                          <Product sid={"augmented-mobility"} />
                      </Slider>
                  </div> */}
              </Abschnitt>

              <Trenner id="SERVICE" small={true}/>
              <Abschnitt line={true} headline={"SERVICES"}>
                  <p>Un&shy;sere Leis&shy;tun&shy;gen sind um&shy;fan&shy;gre&shy;ich und viel&shy;seit&shy;ig. Als Mul&shy;ti&shy;me&shy;dia De&shy;sign&shy;er*in&shy;nen haben wir im&shy;mer das große Ganze im Blick. Dabei entschei&shy;den Pro&shy;jek&shy;tum&shy;fang, zeitlich&shy;er Rah&shy;men, Wün&shy;sche und Bedürfnisse des Kun&shy;den und der Ziel&shy;gruppe zu welchen Teilen sich die Leis&shy;tun&shy;gen zusam&shy;menset&shy;zen und worauf wir un&shy;seren Schw&shy;er&shy;punkt leg&shy;en. Am Ende ist das fer&shy;tige Pro&shy;jekt im&shy;mer das Ergeb&shy;nis eines kom&shy;plex&shy;en Leis&shy;tungs- und Ser&shy;viceum&shy;fangs.</p>
                  <Trenner verysmall={true}/>
                  <Services>
                      <Service name={"BERATUNG"} image={Beratung} children={"Test?"}>
                      Wir be&shy;rat&shy;en Sie mit kreativ&shy;en Im&shy;pulsen, struk&shy;tur&shy;ierten Me&shy;tho&shy;den und or&shy;ga&shy;nisatorisch&shy;er Er&shy;fahrung. Sowohl in der Pro&shy;jekt&shy;pla&shy;nung als auch zu The&shy;men wie Ex&shy;ten&shy;ded Re&shy;al&shy;i&shy;ty, In&shy;ter&shy;ac&shy;tion&shy;de&shy;sign oder Us&shy;er Ex&shy;pe&shy;ri&shy;ence De&shy;sign beant&shy;worten wir ihre Fra&shy;gen di&shy;rekt oder in gemein&shy;samen Work&shy;shops. Auf diese Weise kön&shy;nen Sie ihr Pro&shy;jekt op&shy;ti&shy;mal pla&shy;nen.
                      </Service>

                      <Service name={"KONZEPT-ENTWICKLUNG"} image={Konzept}>
                          Entsprechend ihres in&shy;di&shy;vidu&shy;ellen Be&shy;darfs ent&shy;wick&shy;eln wir ini&shy;tiale Ideen, be&shy;treiben Tech&shy;nolo&shy;gi&shy;escout&shy;ing und schaf&shy;fen aus&shy;sagekräftige Us&shy;er-Sto&shy;rys, sowie In&shy;ter&shy;ak&shy;tion&shy;skonzepte. Dies tun wir ent&shy;lang eines eige&shy;nen method&shy;is&shy;chen Leit&shy;fadens, der es uns er&shy;möglicht kom&shy;plexe, aber le&shy;icht kom&shy;mu&shy;nizier&shy;bare Konzepte zu schaf&shy;fen.
                      </Service>

                      <Service name={"UI/UX DESIGN"} image={uiux}>
                          Das Nutzer&shy;erleb&shy;nis ist für uns zen&shy;tral. Als Team aus Mul&shy;ti&shy;me&shy;di&shy;a De&shy;signer*&shy;in&shy;nen und En&shy;twick&shy;ler*in&shy;nen verbinden wir Funk&shy;tion&shy;al&shy;ität mit ein&shy;ma&shy;li&shy;gen Er&shy;leb&shy;nis&shy;sen und de&shy;tail&shy;lierten sowie klaren Be&shy;dienober&shy;flächen. Dabei deck&shy;en wir die Pro&shy;duk&shy;tion von Präsen&shy;ta&shy;tion&shy;s&shy;ma&shy;te&shy;ri&shy;al eben&shy;so wie die Er&shy;stel&shy;lung von 2D und 3D In&shy;hal&shy;ten ab.
                      </Service>

                      <Service name={"INTERACTION DESIGN"} image={Interaction}>
                          Tech&shy;nolo&shy;gie ist kein Selb&shy;stzweck! In&shy;ter&shy;ak&shy;tionen sind zen&shy;traler Be&shy;standteil eines ziel&shy;grup&shy;pen&shy;gerecht&shy;en Pro&shy;duk&shy;ts und nicht alle In&shy;halte lassen sich auf die gle&shy;iche Weise ver&shy;mit&shy;teln. Aus diesem Grund verbinden wir Tech&shy;nolo&shy;gien wie Gesten-, Sprach&shy;s&shy;teuerun&shy;gen und vi&shy;suelle In&shy;ter&shy;faces zu ziel&shy;grup&shy;penori&shy;en&shy;tierten mul&shy;ti&shy;modalen Gesamt&shy;pro&shy;duk&shy;ten.
                      </Service>

                      <Service name={"SOFTWARE-ENTWICKLUNG"} image={softwareentwicklung}>
                          Als Mul&shy;ti&shy;me&shy;dia De&shy;sign Agen&shy;tur bi&shy;eten wir Gestal&shy;tung und En&shy;twick&shy;lung aus ein&shy;er Hand. Un&shy;sere Werkzeuge sind an dy&shy;namis&shy;che Gestal&shy;tungsan&shy;forderun&shy;gen an&shy;pass&shy;bar, während wir unser De&shy;sign prak&shy;tisch evaluieren. Dieser in&shy;ter&shy;diszi&shy;plinäre Ansatz er&shy;möglicht kreative Lö&shy;sun&shy;gen, die sich ent&shy;lang gängiger En&shy;twick&shy;lungsprozesse in Ihre be&shy;stehen&shy;den Frame&shy;works in&shy;te&shy;gri&shy;eren.
                      </Service>

                      <Service name={"PRÄSENTATION/ SUPPORT"} image={PraesentationWebp}>
                          Wir bi&shy;eten je nach Pro&shy;jek&shy;tan&shy;forderung die Bere&shy;it&shy;stel&shy;lung in den App-Stores, Beschaf&shy;fung der Hard&shy;ware, Pro&shy;jekt&shy;web&shy;sites, Präsen&shy;ta&shy;tions&shy;un&shy;ter&shy;stützung sowie Sup&shy;port et&shy;waiger Web&shy;lös&shy;ungen und Daten&shy;banken über einen gewün&shy;scht&shy;en Zeitraum an.
                      </Service>

                  </Services>
              </Abschnitt>

              <Trenner small={true}/>
              <Abschnitt line={true} headline={"TOOLING"}>
              <p>Die von uns geschaffenen Erlebnisse und Produkte sind zukunftsweisend und individuell auf ihr Projekt und ihre Zielgruppe zurechtgeschnitten. Wir implementieren die neusten Funktionen aus einer Vielzahl von Systemen und Toolkits zusammen mit eigens entwickelten Werkzeugen in einem Framework. Dieses modulare Framework ermöglicht es uns gleichzeitig effizient und individuell vorzugehen. Mehrfachentwicklungen werden vermieden, während eigene Systeme stetig evaluiert und weiterentwickelt werden.  </p>
                      
                  <Trenner verysmall={true}/>
                  <Toolings>
                      <Tooling name={"MULTI-USER"} image={multiuser}>
                      Ein isoliertes Nutzer&shy;erleb&shy;nis macht die Ver&shy;mit&shy;tlung von In&shy;hal&shy;ten schw&shy;er. Wir haben Werkzeuge en&shy;twick&shy;elt, durch welche wir Ihre Pro&shy;duk&shy;te für mehrere Men&shy;schen gle&shy;ichzeit&shy;ig und gemein&shy;sam er&shy;leb&shy;bar machen. 
                      </Tooling>
                      <Tooling name={"SPATIAL"} image={spatial}>
                      Um virtuelle und reale Wel&shy;ten deck&shy;ungs&shy;gle&shy;ich zu über&shy;lagern, braucht es ein Ver&shy;ständ&shy;nis für den Raum. Wir nutzen Eige&shy;nen&shy;twick&shy;lun&shy;gen und neuste Schnitt&shy;stellen, um das Op&shy;ti&shy;mum für ihr Er&shy;leb&shy;nis zu er&shy;rei&shy;chen.
                      </Tooling>
                      <Tooling name={"CROSS-PLATFORM"} image={crossplattform}>
                      Tablets, Smart&shy;phones, Pro&shy;jek&shy;toren, Head-Up-Dis&shy;plays und Head-Mount&shy;ed-Dis&shy;plays kön&shy;nen Teil des&shy;sel&shy;ben virtuellen Er&shy;leb&shy;niss&shy;es sein. Wir verknüpfen die passenden Tech&shy;nolo&shy;gien für Ihre Ziel&shy;gruppe. 
                      </Tooling>
                      <Tooling name={"IOT"} image={iot}>
                      Durch un&shy;sere Schnitt&shy;stellen er&shy;möglichen wir die In&shy;ter&shy;ak&shy;tion mit Smart Home De&shy;vices, die Ver&shy;net&shy;zung mit Sen&shy;soren und Ak&shy;toren, sowie die Echtzeitvi&shy;su&shy;al&shy;isierung von Sen&shy;sor&shy;da&shy;ten in der Ex&shy;tend&shy;ed Re&shy;al&shy;i&shy;ty.
                      </Tooling>
                      <Tooling name={"MACHINE LEARNING"} image={machinelearning}>
                      Wir nutzen Ma&shy;chine-Learn&shy;ing, um neue in&shy;tu&shy;itive MMIs zu en&shy;twick&shy;eln. Mit Edge De&shy;vices track&shy;en wir Gesten und Kör&shy;per&shy;sprache, um un&shy;sere In&shy;ter&shy;ak&shy;tion&shy;s&shy;möglichkeit&shy;en zu er&shy;weit&shy;ern. 
                      </Tooling>
                  </Toolings>
              </Abschnitt>

              <Trenner small={true}/>
               {/* <Abschnitt line={true} headline={"INSIGHTS"}>
                  <Trenner small={true}/>
                  <Insights/>
                  <Trenner small={true}/>
              </Abschnitt>  */}

              <FlyingContactClick/>
          </Layout>
      </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
