import * as React from 'react';
import './service.css';

const Service = ({name, image, children}) => {
    function onMouseEnterServiceKasten(name) {
        window._paq.push([`trackEvent`, 'UI', 'view', 'service', name]); // Matomo event tracking
    }

    return (
        <div className="col5 service-kasten flex" onMouseEnter={() => onMouseEnterServiceKasten(name)} role='tooltip'>
            <div className="service-card grau-bg" style={{ backgroundImage: `url(${image})`}}>
               
                <h3>{name}</h3>
                <div className={"buttonparent"}>
                    <button className={"service-hover"}><span>i</span></button>
                </div>
            </div>

            <div className="service-card-hidden">
                <h3>{name}</h3>
                <p>{children}</p>
            </div>
        </div>
    )
}
export default Service