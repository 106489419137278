import * as React from 'react';
import './tooling.css';

const Toolings = ({children}) => {
    return (
        <div className={"fullWidth tooling-kasten"}>
            {children}
        </div>
    )
}
export default Toolings