import * as React from 'react';
import "./service.css";

const Services = ({children}) => {
    return (
        <div className={"fullWidth service"}>
            {children}
        </div>
    )
}
export default Services



